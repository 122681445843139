import gql from 'graphql-tag';

import { BLOGPOST_FRAGMENT, FEATURE_PAGE_FRAGMENT, NAVIGATION_PAGE_FRAGMENT } from './fragments';

export const GET_BLOG_POST = gql`
    query QueryType($id: ID!) {
        BlogpostItem(id: $id) {
            ...BlogpostFragment
        }
    }
    ${BLOGPOST_FRAGMENT}
`;

export const GET_BLOG_POSTS = gql`
    query QueryType($per_page: Int, $page: Int, $sort_by: String, $search_term: String) {
        BlogpostItems(
            starts_with: "blog/"
            per_page: $per_page
            excluding_slugs: "blog/"
            page: $page
            sort_by: $sort_by
            search_term: $search_term
        ) {
            items {
                ...BlogpostFragment
            }
            total
        }
    }
    ${BLOGPOST_FRAGMENT}
`;

export const GET_FAQ_PAGE = gql`
    query QueryType($id: ID!) {
        FaqpageItem(id: $id) {
            name
            full_slug
            content {
                _editable
                body
                component
                preview_image {
                    filename
                    alt
                }
                metadata
            }
        }
    }
`;

export const GET_FEATURE_PAGE = gql`
    query QueryType($id: ID!) {
        FeaturepageItem(id: $id) {
            ...FeaturepageFragment
        }
    }
    ${FEATURE_PAGE_FRAGMENT}
`;

export const GET_FEATURE_PAGES = gql`
    query QueryType(
        $starts_with: String
        $per_page: Int
        $page: Int
        $sort_by: String
        $search_term: String
        $excluding_slugs: String
    ) {
        FeaturepageItems(
            starts_with: $starts_with
            excluding_slugs: $excluding_slugs
            per_page: $per_page
            page: $page
            sort_by: $sort_by
            search_term: $search_term
        ) {
            items {
                ...FeaturepageFragment
            }
            total
        }
    }
    ${FEATURE_PAGE_FRAGMENT}
`;

export const GET_NAVIGATION_PAGE = gql`
    query QueryType($id: ID!, $resolve_relations: String) {
        NavigationpageItem(id: $id, resolve_relations: $resolve_relations) {
            ...NavigationpageFragment
        }
    }
    ${NAVIGATION_PAGE_FRAGMENT}
`;

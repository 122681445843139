import gql from 'graphql-tag';

export const BLOGPOST_FRAGMENT = gql`
    fragment BlogpostFragment on BlogpostItem {
        name
        full_slug
        content {
            _editable
            read_time
            body
            metadata
            showTranslation
            preview_image {
                filename
                alt
            }
        }
        published_at
        first_published_at
    }
`;

export const FEATURE_PAGE_FRAGMENT = gql`
    fragment FeaturepageFragment on FeaturepageItem {
        name
        full_slug

        content {
            _editable
            body
            showTranslation
            component
            preview_image {
                filename
                alt
            }
            metadata
        }
        published_at
        first_published_at
    }
`;

export const NAVIGATION_PAGE_FRAGMENT = gql`
    fragment NavigationpageFragment on NavigationpageItem {
        name
        full_slug
        content {
            _editable
            header
            footer
            body
            banner
            component
            previewImage {
                filename
                alt
            }
            metadata
        }
        published_at
        first_published_at
    }
`;

import { Apollo, ApolloBase } from 'apollo-angular';
import { retryBackoff } from 'backoff-rxjs';
import { Observable, map } from 'rxjs';
import { ISbStoriesParams } from 'storyblok-js-client';

import { Inject, Injectable, InjectionToken } from '@angular/core';

import { BlogpostDto, BlogpostItemsDto } from '@cgib/shared/dtos/storyblok/blogpost.dto';
import { FaqpageDto } from '@cgib/shared/dtos/storyblok/faqpage.dto';
import { FeaturepageDto, FeaturepageItemsDto } from '@cgib/shared/dtos/storyblok/feature-page.dto';
import { NavigationpageDto } from '@cgib/shared/dtos/storyblok/navigation-page.dto';

import {
    GET_BLOG_POST,
    GET_BLOG_POSTS,
    GET_FAQ_PAGE,
    GET_FEATURE_PAGE,
    GET_FEATURE_PAGES,
    GET_NAVIGATION_PAGE
} from './queries';

export const GRAPHQL_STORYBLOK_CONFIG = new InjectionToken<StoryblokGraphqlConfig>('GRAPHQL_STORYBLOK_CONFIG');
export interface StoryblokGraphqlConfig {
    token: string;
}

@Injectable({
    providedIn: 'root'
})
export class StoryblokGraphqlService {
    apollo: ApolloBase<unknown>;
    constructor(
        private readonly _apollo: Apollo,
        @Inject(GRAPHQL_STORYBLOK_CONFIG) private readonly config: StoryblokGraphqlConfig
    ) {
        this.apollo = this._apollo.use('storyblok');
    }
    getBlogPost$(slug: string): Observable<BlogpostDto> {
        return this.apollo
            .query<{ BlogpostItem: BlogpostDto }>({
                query: GET_BLOG_POST,
                variables: {
                    id: 'blog/' + slug
                }
            })
            .pipe(
                this.retry(),
                map(result => result.data.BlogpostItem)
            );
    }
    getBlogPosts$(params: ISbStoriesParams, signal?: AbortSignal): Observable<BlogpostItemsDto> {
        return this.apollo
            .query<{ BlogpostItems: BlogpostItemsDto }>({
                query: GET_BLOG_POSTS,
                variables: { ...params },
                context: {
                    fetchOptions: {
                        signal
                    }
                }
            })
            .pipe(
                this.retry(),
                map(result => result.data.BlogpostItems)
            );
    }
    getFaqPage$(id: string): Observable<FaqpageDto> {
        return this.apollo
            .query<{ FaqpageItem: FaqpageDto }>({
                query: GET_FAQ_PAGE,
                variables: { id }
            })
            .pipe(
                this.retry(),
                map(result => result.data.FaqpageItem)
            );
    }

    getFeaturePage$(id: string): Observable<FeaturepageDto> {
        return this.apollo
            .query<{ FeaturepageItem: FeaturepageDto }>({
                query: GET_FEATURE_PAGE,
                variables: { id }
            })
            .pipe(
                this.retry(),
                map(result => result.data.FeaturepageItem)
            );
    }
    getFeaturePages$(params: ISbStoriesParams, signal?: AbortSignal): Observable<FeaturepageItemsDto> {
        return this.apollo
            .query<{ FeaturepageItems: FeaturepageItemsDto }>({
                query: GET_FEATURE_PAGES,
                variables: { ...params },
                context: {
                    fetchOptions: {
                        signal
                    }
                }
            })
            .pipe(
                this.retry(),
                map(result => result.data.FeaturepageItems)
            );
    }
    getNavigationPage$(slug: string): Observable<NavigationpageDto> {
        return this.apollo
            .query<{ NavigationpageItem: NavigationpageDto }>({
                query: GET_NAVIGATION_PAGE,
                variables: { id: slug }
                // variables: { id: slug, resolve_relations: 'navigation-item.sections' }
            })
            .pipe(
                this.retry(),
                map(result => result.data.NavigationpageItem)
            );
    }

    private retry() {
        return retryBackoff({
            initialInterval: 100,
            maxRetries: 3,
            resetOnSuccess: true
        });
    }
}
